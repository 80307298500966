"use client";
import { useState, useRef, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import TailwindAdvancedEditor from "@/components/tailwind/advanced-editor";
import { Button } from "@/components/tailwind/ui/button";
import { encode } from "base64-arraybuffer";

const firebaseConfig = {
  apiKey: "AIzaSyDEkmpcHOb6Qm0sja3ecFnwiNvQJf4-ubk",
  authDomain: "samugen-81f3c.firebaseapp.com",
  projectId: "samugen-81f3c",
  storageBucket: "samugen-81f3c.appspot.com",
  messagingSenderId: "905196662319",
  appId: "1:905196662319:web:1477066b3794e34b6520da",
  measurementId: "G-JLH1Z8KLK0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default function Page() {
  const [language, setLanguage] = useState("Korean");
  const [style, setStyle] = useState("Professional");
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleAuth = () => {
    if (isSignUp) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setUser(userCredential.user);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setUser(userCredential.user);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleSendMessage = () => {
    if (input.trim()) {
      const newMessage = { text: input, sender: "user" };
      setMessages([...messages, newMessage]);
      setInput("");
      // Here you would send the message to the ChatGPT API and handle the response
      const botResponse = { text: "기능 구현 중입니다.", sender: "bot" };
      setMessages([...messages, newMessage, botResponse]);
    }
  };

  if (!user) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h1 className="text-2xl mb-4">{isSignUp ? "Sign Up" : "Login for test"}</h1>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-2 p-2 border rounded"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mb-2 p-2 border rounded"
        />
        <Button onClick={handleAuth}>{isSignUp ? "Sign Up" : "Login"}</Button>
      </div>
    );
  }

  return (
    <div className="relative flex min-h-screen flex-col items-center gap-4 py-4 sm:px-5">
      <div className="flex flex-col w-full max-w-full items-center gap-2 px-4">
        <div className="relative w-full flex flex-col items-start mt-12 gap-2"> </div>
        <div id="editor-content" className="w-full flex justify-center items-center">
          <TailwindAdvancedEditor initialLanguage={"Korean"} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        </div>
        <div
          className="fixed top-0 right-0 h-full bg-gray-100 border-l border-gray-300"
          style={{
            width: sidebarOpen ? "400px" : "0px",
            transition: "width 0.3s ease",
            overflow: sidebarOpen ? "visible" : "hidden",
          }}
        >
          {sidebarOpen && (
            <div className="flex flex-col h-full p-4">
              <div className="flex-1 overflow-y-auto mb-4">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-2 p-2 rounded-lg ${message.sender === "user" ? "bg-blue-200 self-end" : "bg-gray-200 self-start"}`}
                  >
                    {message.text}
                  </div>
                ))}
              </div>
              <div className="flex items-center">
                <input
                  type="text"
                  className="flex-1 p-2 border rounded mr-2"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Type your message..."
                />
                <Button onClick={handleSendMessage}>Send</Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
