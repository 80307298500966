export const defaultEditorContent = {
  type: "doc",
  content: [
    // {
    //   type: "heading",
    //   attrs: { level: 2 },
    //   content: [{ type: "text", text: "사무젠. AI 글쓰기 툴" }],
    // },
    // {
    //   type: "paragraph",
    //   content: [
    //     {
    //       type: "text",
    //       text: "Samugen은 AI 기반 텍스트 생성 앱입니다. 스마트하고 안전한 오피스 AI 비서로 제안서, 보고서, 공문서 등을 손쉽게 작성할 수 있습니다. Tab을 눌러서 글을 이어서 생성하고, Shift + Tab으로 재생성해보세요!",
    //     },
        
    //   ],
    // },
    // {
    //   type: "heading",
    //   attrs: { level: 3 },
    //   content: [{ type: "text", text: "Installation" }],
    // },
    // {
    //   type: "codeBlock",
    //   attrs: { language: null },
    //   content: [{ type: "text", text: "npm i samugen" }],
    // },
    // {
    //   type: "heading",
    //   attrs: { level: 3 },
    //   content: [{ type: "text", text: "Usage" }],
    // },
    // {
    //   type: "codeBlock",
    //   attrs: { language: null },
    //   content: [
    //     {
    //       type: "text",
    //       text: 'import { Editor } from "samugen";\n\nexport default function App() {\n  return (\n     <Editor />\n  )\n}',
    //     },
    //   ],
    // },
    // {
    //   type: "heading",
    //   attrs: { level: 3 },
    //   content: [{ type: "text", text: "Features" }],
    // },
    // {
    //   type: "orderedList",
    //   attrs: { tight: true, start: 1 },
    //   content: [
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [{ type: "text", text: "Continue writing: Natural sentence completion and idea expansion support" }],
    //         },
    //       ],
    //     },
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [
    //             { type: "text", text: "Keyword-based writing: Automatically generate draft reports from keywords" },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [
    //             { type: "text", text: "Correction: Automatically correct awkward sentences and errors" },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [
    //             { type: "text", text: "Concise writing: Summarize document content concisely" },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [
    //             { type: "text", text: "Translation: Support for multilingual document translation" },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [
    //             { type: "text", text: "Email and letter formats: Writing tailored to different purposes" },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       type: "listItem",
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [
    //             { type: "text", text: "Python coding support: Code review and optimal suggestions" },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   type: "image",
    //   attrs: {
    //     src: "https://www.samugen.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fai_ninja.498f25fc.jpg&w=1080&q=75",
    //     alt: "banner.png",
    //     title: "banner.png",
    //     width: null,
    //     height: null,
    //   },
    // },
    // { type: "horizontalRule" },
    // {
    //   type: "heading",
    //   attrs: { level: 3 },
    //   content: [{ type: "text", text: "Learn more" }],
    // },
    // {
    //   type: "taskList",
    //   content: [
    //     {
    //       type: "taskItem",
    //       attrs: { checked: false },
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [
    //             { type: "text", text: "Star us on " },
    //             {
    //               type: "text",
    //               marks: [
    //                 {
    //                   type: "link",
    //                   attrs: {
    //                     href: "https://github.com/your-repo/samugen",
    //                     target: "_blank",
    //                   },
    //                 },
    //               ],
    //               text: "GitHub",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       type: "taskItem",
    //       attrs: { checked: false },
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [
    //             { type: "text", text: "Install the " },
    //             {
    //               type: "text",
    //               marks: [
    //                 {
    //                   type: "link",
    //                   attrs: {
    //                     href: "https://www.npmjs.com/package/samugen",
    //                     target: "_blank",
    //                   },
    //                 },
    //               ],
    //               text: "NPM package",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       type: "taskItem",
    //       attrs: { checked: false },
    //       content: [
    //         {
    //           type: "paragraph",
    //           content: [
    //             {
    //               type: "text",
    //               marks: [
    //                 {
    //                   type: "link",
    //                   attrs: {
    //                     href: "https://vercel.com/templates/next.js/samugen",
    //                     target: "_blank",
    //                   },
    //                 },
    //               ],
    //               text: "Deploy your own",
    //             },
    //             { type: "text", text: " to Vercel" },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],
};
