// generative-menu-switch.tsx
import { EditorBubble, useEditor } from "novel";
import { removeAIHighlight } from "novel/extensions";
import { } from "novel/plugins";
import { Fragment, type ReactNode, useEffect } from "react";
import { Button } from "../ui/button";
import Magic from "../ui/icons/magic";
import { FileText, ShieldCheck } from "lucide-react";
import { AISelector } from "./ai-selector";
import { TemplateSelector } from "./template-selector";
import { CriticSelector } from "./critic-selector.";

interface GenerativeMenuSwitchProps {
  children: ReactNode;
  open: boolean;
  is_template: boolean;
  is_critic: boolean;
  onOpenChange: (open: boolean) => void;
  templateOpenChange: (open: boolean) => void;
  criticOpenChange: (open: boolean) => void;
  language: string; // language prop 추가
  model: string; // model prop 추가
}

const GenerativeMenuSwitch = ({
  children,
  open,
  is_template,
  is_critic,
  onOpenChange,
  templateOpenChange,
  criticOpenChange,
  language, // language prop 추가
  model, // model prop 추가
}: GenerativeMenuSwitchProps) => {
  const { editor } = useEditor();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        onOpenChange(!open);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


  useEffect(() => {
    if (!open) removeAIHighlight(editor);
    if (!is_template) removeAIHighlight(editor);
    if (!is_critic) removeAIHighlight(editor);
  }, [open]);
  return (
    <EditorBubble
      tippyOptions={{
        placement: open ? "bottom-start" : "top",
        onHidden: () => {
          onOpenChange(false);
          templateOpenChange(false);
          editor.chain().unsetHighlight().run();
        },
      }}
      className={`flex w-full max-w-[100vw] overflow-hidden ${open ? "mb-4" : "mb-0"} rounded-md border border-muted bg-background shadow-2xl`}      >
      {is_critic && !open && !is_template && (
        <CriticSelector
          open={is_critic}
          onOpenChange={criticOpenChange}
          language={language}
          model={model}
        />
      )}
      {is_template && !open && !is_critic && (
        <TemplateSelector
          open={is_template}
          onOpenChange={templateOpenChange}
          language={language}
          model={model}
        />
      )}
      {open && !is_template && !is_critic && (
        <AISelector
          open={open}
          onOpenChange={onOpenChange}
          language={language}
          model={model}
        />
      )}
      {!open && !is_template && !is_critic && (
        <>
          <Fragment>
            <Button
              className="gap-1 rounded-none text-blue-700 shadow-2xl"
              variant="ghost"
              onClick={() => onOpenChange(true)}
              size="sm"
            >
              <Magic className="h-5 w-5" />
              AI Assistant
            </Button>
            {children}
          </Fragment>
          <Fragment>
            <Button
              className="gap-1 rounded-none text-blue-700 shadow-2xl"
              variant="ghost"
              onClick={() => templateOpenChange(true)}
              size="sm"
            >
              <FileText className="h-5 w-5" />
              템플릿
            </Button>
            {children}
          </Fragment>
          <Fragment>
            <Button
              className="gap-1 rounded-none text-red-700 shadow-2xl"
              variant="ghost"
              // onClick={() => criticOpenChange(true)}
              // onClick={() => criticOpenChange(true)}
              onClick={() => alert("아직 준비중인 기능입니다.")}
              size="sm"
            >
              <ShieldCheck className="h-5 w-5" />
              크리틱
            </Button>
            {children}
          </Fragment>
        </>
      )}
    </EditorBubble>
  );
};

export default GenerativeMenuSwitch;
