import {
  Check,
  CheckSquare,
  ChevronDown,
  Code,
  Heading1,
  Heading2,
  Heading3,
  Printer,
  ListOrdered,
  type LucideIcon,
  TextIcon,
  TextQuote,
  LogOut,
  HelpCircle,
} from "lucide-react";
import { EditorBubbleItem, useEditor } from "novel";
import { getAuth, signOut } from "firebase/auth";

import { Button } from "@/components/tailwind/ui/button";
import {
  PopoverContent,
  PopoverTrigger,
} from "@/components/tailwind/ui/popover";
import { Popover } from "@radix-ui/react-popover";

export type SelectorItem = {
  name: string;
  icon: LucideIcon;
  command: (editor: ReturnType<typeof useEditor>["editor"]) => void;
  isActive: (editor: ReturnType<typeof useEditor>["editor"]) => boolean;
};
const handleLogout = () => {
  const auth = getAuth();
  signOut(auth);
};
const gotolink = () => {
  window.open("https://www.samugen.com");
};
const handlePrintClick = () => {
  const element = document.getElementsByClassName("tiptap ProseMirror prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full");
  if (element.length > 0) {
    const originalContent = document.body.innerHTML;
    document.body.innerHTML = element[0].outerHTML;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload(); // Optional: Reload to restore the original content
  }
};

const items: SelectorItem[] = [
  {
    name: "인쇄",
    icon: Printer,
    command: () => handlePrintClick(),
    isActive: () => false,
  },
  {
    name: "사용 설명서",
    icon: HelpCircle,
    command: () => gotolink(),
    isActive: () => false,
  },
  {
    name: "로그 아웃",
    icon: LogOut,
    command: () => handleLogout(),
    isActive: () => false,
  },
];
interface NodeSelectorProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const HomeButtons = ({ open, onOpenChange }: NodeSelectorProps) => {
  const { editor } = useEditor();
  if (!editor) return null;
  const activeItem = items.filter((item) => item.isActive(editor)).pop() ?? {
    name: "Multiple",
  };

  return (
    <Popover modal={true} open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger
        asChild
        className="gap-2 rounded-none border-none hover:bg-zinc-300 focus:ring-0 w-16"
      >
        <Button size="sm" variant="ghost" className="gap-2">
          <span className="whitespace-nowrap text-sm">홈</span>
          <ChevronDown className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent sideOffset={5} align="start" className="w-32 p-1">
        {items.map((item) => (
          <EditorBubbleItem
            key={item.name}
            onSelect={(editor) => {
              item.command(editor);
              onOpenChange(false);
            }}
            className="flex cursor-pointer items-center justify-between rounded-sm px-2 py-1 text-sm hover:bg-zinc-300"
          >
            <div className="flex items-center space-x-2">
              <div className="rounded-sm border p-1">
                <item.icon className="h-3 w-3" />
              </div>
              <span>{item.name}</span>
            </div>
            {activeItem.name === item.name && <Check className="h-4 w-4" />}
          </EditorBubbleItem>
        ))}
      </PopoverContent>
    </Popover>
  );
};
