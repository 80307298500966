import { useState, useEffect, useRef, useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useCompletion } from 'ai/react';
import { defaultEditorContent } from "@/lib/content";
import { Button } from "@/components/tailwind/ui/button";
import { Undo, Redo } from "lucide-react";
import Menu from "@/components/tailwind/ui/menu";
import {
  EditorCommand,
  EditorCommandEmpty,
  EditorCommandItem,
  EditorCommandList,
  EditorContent,
  EditorBubble,
  type EditorInstance,
  EditorRoot,
  type JSONContent,
} from "novel";
import { ImageResizer, handleCommandNavigation } from "novel/extensions";
import { handleImageDrop, handleImagePaste } from "novel/plugins";
import GenerativeMenuSwitch from "./generative/generative-menu-switch";
import { uploadFn } from "./image-upload";
import { TextButtons } from "./selectors/text-buttons";
import { slashCommand, suggestionItems } from "./slash-command";
import { defaultExtensions } from "./extensions";
import { ColorSelector } from "./selectors/color-selector";
import { LinkSelector } from "./selectors/link-selector";
import { NodeSelector } from "./selectors/node-selector";
import { Printer, X, Plus, ChevronRight, ChevronLeft } from "lucide-react";
import { HomeButtons } from "./selectors/home-buttons";
import { StyleButtons } from "./selectors/style-buttons";
import { LayoutButtons } from "./selectors/layout-buttons";
import { InsertButtons } from "./selectors/insert-buttons";
import { AISelectorQA } from "./generative/ai-selector-localQA"; // AISelectorQA import 추가
import Modal from "./ui/modal"; // Import the modal component
// import { read } from "fs";
import { getAuth } from "firebase/auth";
import { CircularProgress, Alert } from '@mui/material'; // MUI 컴포넌트 import

// import fs from 'fs-extra';
import path from 'path';
import os from 'os';

const Separator = ({ orientation }) => (
  <div
    className={`${orientation === "vertical" ? "w-px " : "h-px w-full"
      } bg-gray-300 mx-2 py-2`}
  />
);
const hljs = require("highlight.js");

const extensions = [...defaultExtensions, slashCommand];
const TailwindAdvancedEditor = ({ initialLanguage, sidebarOpen, setSidebarOpen }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [hasGenerated, setHasGenerated] = useState(false);
  const lastInputTimeRef = useRef(Date.now());

  const [editorInstance, setEditorInstance] = useState<EditorInstance | null>(null);
  // const [readOnlyEditorInstance, setReadOnlyEditorInstance] = useState<EditorInstance | null>(null);

  const [initialContent, setInitialContent] = useState<null | JSONContent>(
    null
  );
  const [saveStatus, setSaveStatus] = useState("Saved");
  const [charsCount, setCharsCount] = useState();

  const [openNode, setOpenNode] = useState(false);
  const [openHome, setOpenHome] = useState(false);
  const [openLayout, setOpenLayout] = useState(false);
  const [openStyle, setOpenStyle] = useState(false);
  const [openInsert, setOpenInsert] = useState(false);

  const [openColor, setOpenColor] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [openAI, setOpenAI] = useState(false);
  const [template, setTemplate] = useState(false);
  const [critic, setCritic] = useState(false);
  const [language, setLanguage] = useState(initialLanguage);
  const [model, setModel] = useState("gpt-4o-mini");
  const [fileDropdownOpen, setFileDropdownOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [shortcutsOpen, setShortcutsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');


  const fileDropdownRef = useRef(null);
  const fileButtonRef = useRef(null);

  const [showAISelector, setShowAISelector] = useState(false); // state 추가

  // const [sidebarOpen, setSidebarOpen] = useState(false); // 사이드바 열기/닫기 상태 추가

  const [current_comp, setCurrent_comp] = useState("");

  const [editorHeight, setEditorHeight] = useState("500px");


  useEffect(() => {
    if (editorInstance) {
      const updateHeight = () => {
        const height = `${editorInstance.view.dom.offsetHeight}px`;
        setEditorHeight(height);
      };

      updateHeight();

      // 에디터 내용이 변경될 때마다 높이 업데이트
      editorInstance.on('update', updateHeight);

      return () => {
        editorInstance.off('update', updateHeight);
      };
    }
  }, [editorInstance]);

  // const transferGeneratedContent = useCallback(() => {
  //   console.log("WEFWEFWEFWEFWEFWE");
  //   if (editorInstance && readOnlyEditorInstance) {

  //     const readOnlyContent = readOnlyEditorInstance.getHTML();
  //     console.log(readOnlyContent);
  //     // 변환된 내용을 editorInstance에 삽입
  //     editorInstance.chain().focus().setContent(readOnlyContent);

  //     // hasGenerated 상태 초기화
  //     setHasGenerated(false);
  //   }
  // }, [editorInstance, readOnlyEditorInstance]);



  const { complete, completion } = useCompletion({
    api: '/api/generate',
    onFinish: () => {
      setHasGenerated(true);
      setIsGenerating(false);
    },
  });

  const generateContent = useCallback(async (prompt: string) => {
    // setCurrent_comp_length(0);
    if (editorInstance.getText().length > 0) {
      try {
        setIsGenerating(true);
        setHasGenerated(false);

        const response = await complete(prompt, {
          body: {
            option: "continue_general",
            language,
            language_model: model,
          },
        });

        if (typeof response !== 'string') {

          alert("AI 초당 요청 횟수 제한을 초과했습니다. 잠시 후 다시 시도해주세요.");
          setIsGenerating(false);
          return;

          // 다른 상태 코드에 대한 처리를 여기에 추가할 수 있습니다.
        }

        // 정상적인 응답 처리
        // ... (기존 코드)

      } catch (error) {
        console.error("Error generating content:", error);
        setIsGenerating(false);
        // 에러 메시지 표시
        alert("콘텐츠 생성 중 오류가 발생했습니다. 다시 시도해주세요.");
      }
    }
  }, [language, model, complete, editorInstance]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        console.log("Tab key pressed");
        if (editorInstance) {
          const { from } = editorInstance.state.selection;
          const content = editorInstance.state.doc.textBetween(0, from);
          generateContent(content);
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown, { capture: true });

    return () => {
      document.removeEventListener('keydown', handleKeyDown, { capture: true });
    };

  }, [editorInstance]);


  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.shiftKey && event.key === 'Tab') {
  //       event.preventDefault();
  //       // Clear readOnlyEditorInstance
  //       // readOnlyEditorInstance.commands.setContent('');
  //       const generated = editorInstance.getJSON();
  //       readOnlyEditorInstance.chain().focus().setContent(
  //         { "type": "doc", "content": generated.content }
  //       ).run();

  //       // Get current content from editorInstance
  //       const content = editorInstance.getText();

  //       // Generate new content
  //       generateContent(content);
  //     } else if (event.key === 'Tab') {
  //       event.preventDefault();
  //       if (readOnlyEditorInstance.getText().length > editorInstance.getText().length) {
  //         const generated = readOnlyEditorInstance.getJSON();
  //         editorInstance.chain().focus().setContent(
  //           { "type": "doc", "content": generated.content }
  //         ).run();
  //       } else {
  //         const generated = editorInstance.getJSON();
  //         readOnlyEditorInstance.chain().focus().setContent(
  //           { "type": "doc", "content": generated.content }
  //         ).run();

  //         // Get current content from editorInstance
  //         const content = editorInstance.getText();

  //         // Generate new content
  //         generateContent(content);

  //       }
  //     }
  //   };

  //   document.addEventListener('keydown', handleKeyDown, { capture: true });

  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown, { capture: true });
  //   };
  // }, [transferGeneratedContent, generateContent, editorInstance, readOnlyEditorInstance]);



  // const removeGeneratedText = useCallback(() => {
  //   if (editorInstance) { // hasGenerated 조건 추가
  //     const tr = readOnlyEditorInstance.state.tr.delete(0, readOnlyEditorInstance.state.doc.content.size);
  //     readOnlyEditorInstance.view.dispatch(tr);
  //     const generated = editorInstance.getJSON();
  //     readOnlyEditorInstance.chain().focus().setContent(
  //       { "type": "doc", "content": generated.content }
  //     ).run();
  //   }
  // }, [editorInstance]); // hasGenerated 추가

  useEffect(() => {
    if (completion && editorInstance) {
      console.log(completion)
      try {
        const { from } = editorInstance.state.selection;

        // Find the index where the new content starts
        let newContentStartIndex = 0;
        while (newContentStartIndex < completion.length &&
          current_comp[newContentStartIndex] === completion[newContentStartIndex]) {
          newContentStartIndex++;
        }

        const newContent = completion.slice(newContentStartIndex);

        if (newContent) {
          editorInstance.chain().focus().insertContentAt(
            from,
            newContent,
            { parseOptions: { preserveWhitespace: "full" } }
          ).run();
        }

        setCurrent_comp(completion);
      } catch (error) {
        console.error("Error inserting completion:", error);
        // 에러 처리 로직
      }
    }
  }, [completion, editorInstance, current_comp]);
  // const checkAndGenerateContent = useCallback(async () => {
  //   if (isGenerating) return;

  //   const currentTime = Date.now();
  //   if (currentTime - lastInputTimeRef.current > 3500) {
  //     if (readOnlyEditorInstance && editorInstance) {
  //       const readOnlyLength = readOnlyEditorInstance.getText().length;
  //       const editorLength = editorInstance.getText().length;

  //       if (readOnlyLength <= editorLength) {
  //         if (readOnlyLength == 0) {
  //           readOnlyEditorInstance.chain().focus().insertContent(editorInstance.getHTML());
  //         }
  //         setIsGenerating(true);
  //         const content = editorInstance.getText();
  //         await generateContent(content);
  //         setIsGenerating(false);
  //       }
  //     }
  //   }
  // }, [isGenerating, hasGenerated, generateContent, editorInstance, readOnlyEditorInstance]);

  // useEffect(() => {
  //   const interval = setInterval(checkAndGenerateContent, 3500);
  //   return () => clearInterval(interval);
  // }, [checkAndGenerateContent]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        fileDropdownRef.current &&
        !fileDropdownRef.current.contains(event.target) &&
        fileButtonRef.current &&
        !fileButtonRef.current.contains(event.target)
      ) {
        setFileDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Apply Codeblock Highlighting on the HTML from editor.getHTML()
  const highlightCodeblocks = (content: string) => {
    const doc = new DOMParser().parseFromString(content, "text/html");
    doc.querySelectorAll("pre code").forEach((el) => {
      // @ts-ignore
      // https://highlightjs.readthedocs.io/en/latest/api.html?highlight=highlightElement#highlightelement
      hljs.highlightElement(el);
    });
    return new XMLSerializer().serializeToString(doc);
  };

  const debouncedUpdates = useDebouncedCallback(
    async (editor: EditorInstance) => {
      const json = editor.getJSON();
      setCharsCount(editor.storage.characterCount.words());
      window.localStorage.setItem(
        "html-content",
        highlightCodeblocks(editor.getHTML())
      );
      window.localStorage.setItem("novel-content", JSON.stringify(json));
      window.localStorage.setItem(
        "markdown",
        editor.storage.markdown.getMarkdown()
      );
      setSaveStatus("Saved");
    },
    500
  );

  useEffect(() => {
    const content = window.localStorage.getItem("novel-content");
    if (content) setInitialContent(JSON.parse(content));
    else setInitialContent(defaultEditorContent);

    // Set body overflow-y hidden
    document.body.style.overflowY = "hidden";

    // Always show scrollbar to prevent layout shift
    document.body.style.overflow = "overlay";

    return () => {
      // Reset overflow-y style when component unmounts
      document.body.style.overflowY = "auto";
      document.body.style.overflow = "";
    };
  }, []);

  if (!initialContent) return null;

  // file
  const toggleFileDropdown = () => {
    setFileDropdownOpen((prev) => !prev);
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const chunkText = (text, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < text.length; i += chunkSize) {
      chunks.push(text.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });

    setIsUploading(true);
    setUploadStatus('Aliparser가 동작 중입니다...');

    try {
      const response = await fetch('/api/update', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const result = await response.json();
      console.log('Upload successful:', result);
      setUploadStatus('업로드 성공!');
    } catch (error) {
      console.error('Error uploading files:', error);
      setUploadStatus('Aliparser 업로드 실패. 다시 시도해주세요.');
    } finally {
      setIsUploading(false);
      setTimeout(() => setUploadStatus(''), 3000); // 3초 후 상태 메시지 제거
    }
  };

  const handleShortcutsClick = () => {
    setShortcutsOpen(true);
  };

  const handleShortcutsClose = () => {
    setShortcutsOpen(false);
  };

  const handlePrintClick = () => {
    const element = document.getElementById("editor-content");
  };

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <div className={`relative w-full  ${sidebarOpen ? "pr-[400px]" : "pr-0 max-w-screen-lg"}`} >

      {isUploading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <CircularProgress />
        </div>
      )}

      {uploadStatus && (
        <Alert severity={uploadStatus.includes('성공') ? 'success' : 'info'}
          className="fixed bottom-5 right-5 z-50">
          {uploadStatus}
        </Alert>
      )}
      <style jsx global>{`
      .ProseMirror span[data-generated] {
        opacity: 0.5;
      }
    `}</style>
      <div className="flex fixed right-5 top-5 z-50 mb-5 gap-2"></div>
      <EditorRoot>
        {/* <EditorContent
          initialContent={initialContent}
          extensions={extensions}
          className={`absolute top-0 left-0 w-full min-h-[${editorHeight}] ${sidebarOpen ? "pr-[400px]" : "pr-0 max-w-screen-lg"} bg-background sm:mb-[calc(20vh)] sm:rounded-lg  pointer-events-none opacity-50`}
          editorProps={{
            // ... existing editorProps ...
            attributes: {
              class:
                "prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full opacity-50",
            },
          }}
          onCreate={({ editor }) => {
            // setReadOnlyEditorInstance(editor);
            editor.setEditable(false);
          }}
          slotAfter={<ImageResizer />}
        /> */}

        <EditorContent
          initialContent={initialContent}
          extensions={extensions}
          className={`relative min-h-[500px] bg-background-transparent sm:mb-[calc(20vh)] sm:rounded-lg transition-all duration-300 `}
          editorProps={{
            handleDOMEvents: {
              keydown: (_view, event) => handleCommandNavigation(event),
            },
            handlePaste: (view, event) =>
              handleImagePaste(view, event, uploadFn),
            handleDrop: (view, event, _slice, moved) =>
              handleImageDrop(view, event, moved, uploadFn),
            attributes: {
              class:
                "prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full",
            },
          }}
          onUpdate={({ editor }) => {
            // removeGeneratedText();
            debouncedUpdates(editor);
            setSaveStatus("Unsaved");
            lastInputTimeRef.current = Date.now();
          }}
          onCreate={({ editor }) => {
            setEditorInstance(editor);
            editor.setEditable(true);
          }}
          slotAfter={<ImageResizer />}
        >
          <div
            className="fixed top-0 left-0 h-16 z-40 bg-zinc-100 flex items-center justify-center"
            style={{
              right: sidebarOpen ? "400px" : "0px",
              transition: "right 0.3s ease",
            }}
          >
            {/* 사이드바 열기/닫기 버튼 */}
            <button
              onClick={toggleSidebar}
              className="absolute right-5 top-1/2 transform -translate-y-1/2 bg-gray-200 text-black px-2 py-1 rounded-l flex items-center"
            >
              {sidebarOpen ? (
                <>
                  <ChevronRight width={16} className="mr-2" />
                  <span className="mr-2">chat</span>
                </>
              ) : (
                <>
                  <ChevronLeft width={16} className="mr-2" />
                  <span className="mr-2">chat</span>
                </>
              )}
            </button>
            <div
              className="fixed top-0 left-0 h-16 z-40 bg-zinc-100 flex items-center justify-center"
            >
              <div className="flex items-center gap-2">
                <div className="px-2 py-2 rounded-l  bg-gray-200 text-black ml-5">
                  {getAuth().currentUser.email}
                </div>
              </div>

            </div>

            <HomeButtons open={openHome} onOpenChange={setOpenHome} />
            <LayoutButtons open={openLayout} onOpenChange={setOpenLayout} />
            <InsertButtons open={openInsert} onOpenChange={setOpenInsert} />

            <Separator orientation="vertical" />

            <div className="mx-1"></div>
            <div className="flex items-center">
              <button
                className={`text-sm px-3 py-1 h-7 rounded-l-xl outline-none focus:outline-none ${model === "Quality"
                  ? "bg-black text-white"
                  : "bg-gray-200 text-black"
                  }`}
                onClick={() => {
                  alert("테스트 버전에서는 SLM을 사용할 수 없습니다.");
                }}
              >
                Quality
              </button>
              <div
                className={`bg-zinc-100 w-px h-6`}
              />
              <button
                className={`text-sm px-3 py-1 h-7 outline-none focus:outline-none ${model === "Speed"
                  ? "bg-black text-white"
                  : "bg-gray-200 text-black"
                  }`}
                onClick={() => {
                  alert("테스트 버전에서는 SLM을 사용할 수 없습니다.");
                }}
              >
                Speed
              </button>

              <div
                className={`bg-zinc-100 w-px h-6`}
              />
              <button
                className={`text-xs px-3 py-1 h-7 rounded-r-xl outline-none focus:outline-none ${model === "gpt-4o-mini"
                  ? "bg-red-800 text-white"
                  : "bg-gray-200 text-black"
                  }`}
                onClick={() => {
                  // alert("gpt-4o-mini는 외부 API입니다. privacy를 보장할 수 없습니다.");
                  alert("gpt는 외부 API입니다. privacy를 보장할 수 없습니다.");
                  setModel("gpt-4o-mini");
                }}
              >
                gpt-4o
              </button>
            </div>
            <div className="mx-1"></div>

            <Separator orientation="vertical" />
            <div className="pl-2 flex items-center">
              <button
                className={`text-sm px-3 py-1 rounded-l-xl outline-none focus:outline-none ${language === "Korean"
                  ? "bg-black text-white"
                  : "bg-gray-200 text-black"
                  }`}
                onClick={() => setLanguage("Korean")}
              >
                한국어로 생성
              </button>
              <div
                className={`bg-zinc-100 w-px h-6`}
              />
              <button
                className={`text-sm px-3 py-1 rounded-r-xl outline-none focus:outline-none ${language === "English"
                  ? "bg-black text-white"
                  : "bg-gray-200 text-black"
                  }`}
                onClick={() => setLanguage("English")}
              >
                영어로 생성
              </button>
            </div>
            <div className="mx-1"></div>
            <Separator orientation="vertical" />
            <div className="flex items-center gap-2 relative">
              <Button
                ref={fileButtonRef}
                className="text-sm px-3 rounded outline-none focus:outline-none"
                variant="ghost"
                onClick={toggleFileDropdown}
              >
                로컬 문서
              </Button>
              {fileDropdownOpen && (
                <div
                  ref={fileDropdownRef}
                  className="absolute top-full right-0 mt-1 w-48 bg-white shadow-md rounded border border-gray-200 z-50"
                >
                  <ul className="max-h-40 overflow-y-auto z-50">
                    {files.map((file, index) => (
                      <li
                        key={index}
                        className="flex items-center justify-between px-2 py-1"
                      >
                        <span className="text-sm">{file.name}</span>
                        <Button
                          className="text-xs p-0 rounded outline-none focus:outline-none"
                          variant="ghost"
                          onClick={() => handleRemoveFile(file.name)}
                        >
                          <X width={16} />
                        </Button>
                      </li>
                    ))}
                    <li className="flex justify-center p-2">
                      <Button
                        className="text-xs p-0 rounded px-4 outline-none focus:outline-none"
                        variant="ghost"
                        onClick={handleFileClick}
                      >
                        <Plus width={16} />
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        multiple
                      />
                    </li>
                    <li className="flex justify-center p-2">
                      <Button
                        className="text-xs p-0 rounded px-4 outline-none focus:outline-none"
                        variant="ghost"
                        onClick={() => setShowAISelector(true)} // AISelectorQA 창 열기
                      >
                        Chat with 로컬 문서
                      </Button>
                    </li>
                  </ul>
                </div>
              )}
              {showAISelector && (
                <Modal
                  open={showAISelector}
                  onClose={() => setShowAISelector(false)}
                  title="Chat with 로컬 문서"
                >
                  <AISelectorQA
                    open={showAISelector}
                    onOpenChange={setShowAISelector}
                    language={language}
                    model={model}
                  />
                </Modal>
              )}
            </div>

            <div
              className="fixed top-16 left-0  h-8 z-40 bg-zinc-200 flex items-center justify-center"
              style={{
                right: sidebarOpen ? "400px" : "0px",
                transition: "right 0.3s ease",
              }}
            >
              <TextButtons />
              <Separator orientation="vertical" />
              <ColorSelector open={openColor} onOpenChange={setOpenColor} />
              <Separator orientation="vertical" />
              <LinkSelector open={openLink} onOpenChange={setOpenLink} />
              <Separator orientation="vertical" />
              {editorInstance && (
                <>
                  <Button
                    className="gap-2 text-xs px-3 rounded-none"
                    variant="ghost"
                    onClick={() => editorInstance.chain().focus().undo().run()}
                  >
                    <Undo width={16} />
                  </Button>
                  <Button
                    className="gap-2 text-xs px-3 rounded-none"
                    variant="ghost"
                    onClick={() => editorInstance.chain().focus().redo().run()}
                  >
                    <Redo width={16} />
                  </Button>
                </>
              )}
            </div>
          </div>

          <EditorCommand className="z-40 h-auto max-h-[330px] overflow-y-auto rounded-md border border-muted bg-background px-1 py-2 shadow-md transition-all">
            <EditorCommandEmpty className="px-2 text-muted-foreground">
              No results
            </EditorCommandEmpty>
            <EditorCommandList>
              {suggestionItems.map((item) => (
                <EditorCommandItem
                  value={item.title}
                  onCommand={(val) => item.command(val)}
                  className="flex w-full items-center space-x-2 rounded-md px-2 py-1 text-left text-sm hover:bg-zinc-300 aria-selected:bg-accent outline-none focus:outline-none"
                  key={item.title}
                >
                  <div className="flex h-10 w-10 items-center justify-center rounded-md border border-muted bg-background">
                    {item.icon}
                  </div>
                  <div>
                    <p className="font-medium">{item.title}</p>
                    <p className="text-xs text-muted-foreground">
                      {item.description}
                    </p>
                  </div>
                </EditorCommandItem>
              ))}
            </EditorCommandList>
          </EditorCommand>

          <GenerativeMenuSwitch
            open={openAI}
            is_template={template}
            is_critic={critic}
            templateOpenChange={setTemplate}
            onOpenChange={setOpenAI}
            criticOpenChange={setCritic}
            language={language}
            model={model}
          >
            <div></div>
          </GenerativeMenuSwitch>
        </EditorContent>
      </EditorRoot>
    </div>
  );
};

export default TailwindAdvancedEditor;
