// ai-selector.tsx
import { Command, CommandInput } from "@/components/tailwind/ui/command";
import { useCompletion } from "ai/react";
import { ArrowUp } from "lucide-react";
import { useEditor } from "novel";
import { addAIHighlight } from "novel/extensions";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { toast } from "sonner";
import { Button } from "../ui/button";
import CrazySpinner from "../ui/icons/crazy-spinner";
import Magic from "../ui/icons/magic";
import { ScrollArea } from "../ui/scroll-area";
import AICompletionCommands from "./ai-completion-command";
import TemplateSelectorCommands from "./template-selector-commands";

interface TemplateSelectorProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    language: string; // language prop 추가
    model: string; // model prop 추가
}

export function TemplateSelector({ onOpenChange, language, model }: TemplateSelectorProps) {
    // language prop 추가
    const { editor } = useEditor();
    const [inputValue, setInputValue] = useState("");

    const { completion, complete, isLoading } = useCompletion({
        // api: "http://localhost:3000/api/generate", // API 엔드포인트 수정
        api: "/api/generate",
        onResponse: (response) => {
            if (response.status === 429) {
                toast.error("You have reached your request limit for the day.");
                return;
            }
        },
        onError: (e) => {
            toast.error(e.message);
        },
    });

    const hasCompletion = completion.length > 0;

    return (
        <Command className="w-[440px]">
            {hasCompletion && (
                <div className="flex max-h-[500px]">
                    <ScrollArea>
                        <div className="prose p-2 px-4 prose-sm">
                            <Markdown>{completion}</Markdown>
                        </div>
                    </ScrollArea>
                </div>
            )}

            {isLoading && (
                <div className="flex h-12 w-full items-center px-4 text-sm font-medium text-muted-foreground text-blue-700">
                    <Magic className="mr-2 h-4 w-4 shrink-0  " />
                    AI is thinking
                    <div className="ml-2 mt-1">
                        <CrazySpinner />
                    </div>
                </div>
            )}
            {!isLoading && (
                <>
                    {hasCompletion ? (
                        <AICompletionCommands
                            onDiscard={() => {
                                editor.chain().unsetHighlight().focus().run();
                                onOpenChange(false);
                            }}
                            completion={completion}
                        />
                    ) : (
                        <TemplateSelectorCommands
                            onSelect={
                                (value, option) =>
                                    complete(value, {
                                        body: { option, language, language_model: model },
                                    }) // language 추가
                            }
                        />
                    )}
                </>
            )}
        </Command>
    );
}
