import {
  Pencil,
  Type,
  StepForward,
  Terminal,
  AlignLeft,
  ArrowLeftRight,
  List,
  FileText,
  Minimize2,
  Maximize2,
  Languages,
  Lightbulb,
  FileMinus,
  FileText as SummaryIcon,
  Quote,
  Sparkles,
  HelpCircle,
  ListOrdered,
  ArrowRight,
  RefreshCcw,
  Key,
  Repeat,
  Bookmark,
  Plus,
} from "lucide-react";
import { useEditor } from "novel";
import { getPrevText } from "novel/utils";
import { useEffect } from "react";
import { CommandGroup, CommandItem, CommandSeparator, CommandShortcut } from "../ui/command";

const options_continue = [
  {
    value: "continue",
    label: "계속하기(일반)",
    icon: StepForward,
  },
  {
    value: "oppose", // 추가된 옵션
    label: "대조적 관점으로",
    icon: ArrowLeftRight,
  },
  {
    value: "examples",
    label: "예시를 추가하며",
    icon: List,
  },
  {
    value: "quotations",
    label: "인용구를 활용하며",
    icon: Quote,
  },
  {
    value: "metaphors",
    label: "비유/은유를 활용하며",
    icon: Sparkles,
  },
  {
    value: "rhetoricalquestions",
    label: "수사적 질문으로 환기하며",
    icon: HelpCircle,
  },
  {
    value: "enumeration",
    label: "나열하며",
    icon: ListOrdered,
  },
  {
    value: "sequentialdevelopment",
    label: "순차적으로 전개하며",
    icon: ArrowRight,
  },
  {
    value: "summaryconclusion",
    label: "요약/결론으로 마무리지으며",
    icon: SummaryIcon,
  },
  {
    value: "paradox",
    label: "역설(패러독스)을 활용하며",
    icon: RefreshCcw,
  },
  // {
  //   value: "repetition",
  //   label: "반복하는 전략으로",
  //   icon: Repeat,
  // },
  // {
  //   value: "symbolism",
  //   label: "상징을 도입하며",
  //   icon: Bookmark,
  // },
  {
    value: "followupexplanation",
    label: "후속 지지 설명으로",
    icon: Plus,
  }
];
const options = [
  {
    value: "improve",
    label: "개선",
    icon: Pencil,
  },
  {
    value: "seedblueprint",
    label: "개요",
    icon: FileText,
  },
  // {
  //   value: "shorter",
  //   label: "더 짧게",
  //   icon: Minimize2,
  // },
  {
    value: "longer",
    label: "확장",
    icon: Maximize2,
  },
  {
    value: "summary",
    label: "요약",
    icon: SummaryIcon,
  },
  {
    value: "idea",
    label: "브레인스톰",
    icon: Lightbulb,
  },
];
const options3 = [
  {
    value: "translate_ko",
    label: "한국어",
    icon: Languages,
  },
  {
    value: "translate_en",
    label: "English",
    icon: Languages,
  },
  {
    value: "translate_zh",
    label: "汉语",
    icon: Languages,
  },
  {
    value: "translate_jp",
    label: "日本語",
    icon: Languages,
  },
  {
    value: "translate_de",
    label: "Deutsch",
    icon: Languages,
  },
  {
    value: "translate_fr",
    label: "Français",
    icon: Languages,
  },
];
const options2 = [
  {
    value: "seedtitle",
    label: "제목",
    icon: Type,
  },
  {
    value: "seedsentence",
    label: "문장",
    icon: Terminal,
  },
  {
    value: "seedparagraph",
    label: "문단",
    icon: AlignLeft,
  },
  {
    value: "seedlist",
    label: "리스트",
    icon: List,
  },
];

interface AISelectorCommandsProps {
  onSelect: (value: string, option: string) => void;
}

const AISelectorCommands = ({ onSelect }: AISelectorCommandsProps) => {
  const { editor } = useEditor();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        const key = event.key;
        let option = null;

        const keyMap = {
          '1': 0, '2': 1, '3': 2, '4': 3, '5': 4, '6': 5, '7': 6, '8': 7, '9': 8,
          '0': 9, '-': 10, '=': 11
        };

        option = options_continue[keyMap[key]];
        // if (key in keyMap) {
        //   const index = keyMap[key];
        //   if (index < options.length) {
        //     option = options[index];
        //   } else if (index < options.length + options2.length) {
        //     option = options2[index - options.length];
        //   }
        // }

        if (option) {
          const slice = editor.state.selection.content();
          const text = editor.storage.markdown.serializer.serialize(slice.content);
          onSelect(text, option.value);
          event.preventDefault();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editor, onSelect]);

  const keyLabels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '='];

  return (
    <>
      <CommandGroup heading="다음 글쓰기 전략으로 이어쓰기">
        {options_continue.map((option, index) => (
          <CommandItem
            onSelect={(value) => {
              const slice = editor.state.selection.content();
              const text = editor.storage.markdown.serializer.serialize(slice.content);
              onSelect(text, value);
            }}
            className="flex gap-2 px-4"
            key={option.value}
            value={option.value}
          >
            <option.icon className="h-4 w-4 text-blue-700" />
            {option.label}
            {index < keyLabels.length && (
              <CommandShortcut className="ml-auto text-xs text-muted-foreground">{`Ctrl+${keyLabels[index]}`}</CommandShortcut>
            )}
          </CommandItem>
        ))}
      </CommandGroup>
      <CommandSeparator />
      <CommandGroup heading="글쓰기 도구">
        <div className="flex gap-2 px-4">
          {options.map((option) => (
            <CommandItem
              onSelect={(value) => {
                const slice = editor.state.selection.content();
                const text = editor.storage.markdown.serializer.serialize(slice.content);
                onSelect(text, value);
              }}
              className="flex gap-2"
              key={option.value}
              value={option.value}
            >
              <option.icon className="h-4 w-4 text-blue-700" />
              {option.label}
            </CommandItem>
          ))}
        </div>
      </CommandGroup>
      <CommandSeparator />
      <CommandGroup heading="선택된 영역을 다음 텍스트로 변환">
        <div className="flex gap-2 px-4">
          {options2.map((option) => (
            <CommandItem
              onSelect={(value) => {
                const slice = editor.state.selection.content();
                const text = editor.storage.markdown.serializer.serialize(slice.content);
                onSelect(text, value);
              }}
              className="flex gap-2"
              key={option.value}
              value={option.value}
            >
              <option.icon className="h-4 w-4 text-blue-700" />
              {option.label}
            </CommandItem>
          ))}
        </div>
      </CommandGroup>
      <CommandSeparator />
      <CommandGroup heading="선택된 영역을 아래의 언어로 번역">
        <div className="flex gap-2 px-4">
          {options3.map((option) => (
            <CommandItem
              onSelect={(value) => {
                const slice = editor.state.selection.content();
                const text = editor.storage.markdown.serializer.serialize(slice.content);
                onSelect(text, value);
              }}
              className="flex gap-2"
              key={option.value}
              value={option.value}
            >
              {option.label}
            </CommandItem>
          ))}
        </div>
      </CommandGroup>
    </>
  );
};

export default AISelectorCommands;
