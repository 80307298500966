import {
    Asterisk,
    Pencil,
    Type,
    StepForward,
    Terminal,
    AlignLeft,
    ArrowLeftRight,
    List,
    FileText,
    Minimize2,
    Maximize2,
    Languages,
    Lightbulb,
    FileMinus,
    FileText as SummaryIcon,
    Quote,
    Sparkles,
    HelpCircle,
    ListOrdered,
    ArrowRight,
    RefreshCcw,
    Key,
    Repeat,
    Bookmark,
    Plus,
} from "lucide-react";
import { useEditor } from "novel";
import { getPrevText } from "novel/utils";
import { useEffect } from "react";
import { CommandGroup, CommandItem, CommandSeparator, CommandShortcut } from "../ui/command";

const options_continue = [
    {
        value: "critic1",
        label: "critic",
        icon: Asterisk,
    },
    {
        value: "critic1",
        label: "피드백을 바탕으로 개선",
        icon: Asterisk,
    },
];

interface CriticSelectorCommandsProps {
    onSelect: (value: string, option: string) => void;
}

const CriticSelectorCommands = ({ onSelect }: CriticSelectorCommandsProps) => {
    const { editor } = useEditor();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey) {
                const key = event.key;
                let option = null;

                const keyMap = {
                    '1': 0, '2': 1, '3': 2, '4': 3, '5': 4, '6': 5, '7': 6, '8': 7, '9': 8,
                    '0': 9, '-': 10, '=': 11
                };

                option = options_continue[keyMap[key]];
                // if (key in keyMap) {
                //   const index = keyMap[key];
                //   if (index < options.length) {
                //     option = options[index];
                //   } else if (index < options.length + options2.length) {
                //     option = options2[index - options.length];
                //   }
                // }

                if (option) {
                    const slice = editor.state.selection.content();
                    const text = editor.storage.markdown.serializer.serialize(slice.content);
                    onSelect(text, option.value);
                    event.preventDefault();
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [editor, onSelect]);

    const keyLabels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '='];

    return (
        <>
            <CommandGroup heading="다음 템플릿으로 변형하기">
                {options_continue.map((option, index) => (
                    <CommandItem
                        onSelect={(value) => {
                            const slice = editor.state.selection.content();
                            const text = editor.storage.markdown.serializer.serialize(slice.content);
                            onSelect(text, value);
                        }}
                        className="flex gap-2 px-4"
                        key={option.value}
                        value={option.value}
                    >
                        <option.icon className="h-4 w-4 text-blue-700" />
                        {option.label}
                        {index < keyLabels.length && (
                            <CommandShortcut className="ml-auto text-xs text-muted-foreground">{`Ctrl+${keyLabels[index]}`}</CommandShortcut>
                        )}
                    </CommandItem>
                ))}
            </CommandGroup>
        </>
    );
};

export default CriticSelectorCommands;
